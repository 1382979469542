<template>
  <section class="col-7">
    <modal-loading :is-loading="loading" :message="loadingMessage" />
    <modal-confirmation
      :state-modal="isModal"
      title="削除してもよろしいですか？"
      color="red"
      label="削除"
      @hideModal="toggleModal"
      @apply="deleteItem"
    />
    <h2 v-if="$route.meta === 'new'" class="mb-10">カテゴリの登録</h2>
    <h2 v-if="$route.meta === 'edit'" class="mb-10">カテゴリの編集</h2>

    <section>
      <div>
        <div>
          <v-text-field
            v-model="category.name"
            label="カテゴリ名"
            placeholder="地図ページで表示するカテゴリ名（「公園」「公共施設」など）を入力してください"
            type="text"
            :error-messages="errorMessages.name"
            required
            outlined
          />
        </div>

        <div>
          <v-text-field
            v-model="category.slug"
            label="URL用文字列"
            placeholder="半角英数字を指定してください"
            type="text"
            :error-messages="errorMessages.slug"
            required
            outlined
          />
        </div>

        <div>
          <v-select
            v-model="category.settings.pin_color"
            :items="pinItems"
            item-text="label"
            item-value="color"
            label="ピンの色"
            outlined
          >
            <template v-slot:item="{ item, attrs, on }">
              <v-list-item v-bind="attrs" v-on="on">
                <v-icon :color="item.color"> mdi-map-marker </v-icon>
                {{ item.label }}
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <v-icon :color="item.color"> mdi-map-marker </v-icon>
              {{ item.label }}
            </template>
          </v-select>
        </div>
      </div>

      <div>
        <div class="subtitle-1 mb-2">ピン画像</div>

        <v-card class="d-inline-block mx-auto" outlined>
          <v-col cols="auto">
            <section
              v-if="!pinImagePreview && !category.settings.pin_image"
              class="icons__upload_area"
              @dragleave.prevent
              @dragover.prevent
              @drop.prevent="onFileChange"
            >
              <p class="mb-11">
                画像をここにドロップ<br />
                (.png, .jpgに対応しています)
              </p>
              <p class="mb-12">または</p>
              <v-btn rounded outlined @click="selectFile">
                ファイルを選択
              </v-btn>
            </section>

            <input
              ref="file"
              style="display: none"
              type="file"
              @change="onFileChange"
            />

            <v-img
              v-if="pinImagePreview"
              :src="pinImagePreview"
              height="80"
              width="80"
              class="mx-auto"
            />
            <v-img
              v-if="!pinImagePreview && category.settings.pin_image"
              :src="category.settings.pin_image"
              height="80"
              width="80"
              class="mx-auto"
            />

            <v-card-actions>
              <v-btn
                v-if="category.settings.pin_image"
                class="mr-2"
                color="primary"
                outlined
                @click="selectFile"
              >
                アイコン画像を編集
              </v-btn>
              <v-spacer />
              <v-btn
                v-if="
                  pinImagePreview ||
                  (backupImage && !category.settings.pin_image)
                "
                outlined
                @click="cancelEditImage"
              >
                キャンセル
              </v-btn>
              <v-btn
                v-if="category.settings.pin_image && !pinImagePreview"
                icon
                @click="deleteImage"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-card-actions>
          </v-col>
        </v-card>
      </div>

      <hr class="mt-8 mb-4" />

      <v-col>
        <v-row v-if="$route.meta === 'new'" justify="end">
          <v-btn color="primary" large @click="save"> 保存 </v-btn>
        </v-row>
        <v-row
          v-if="$route.meta === 'edit'"
          justify="space-between"
          align="center"
        >
          <v-btn
            color="red"
            large
            outlined
            :disabled="hasPhotos"
            @click="toggleModal"
          >
            削除する
          </v-btn>
          <v-btn color="primary" large @click="edit"> 上書き保存 </v-btn>
        </v-row>
        <v-row v-if="hasPhotos">
          <p class="mt-4 text-caption">
            ※紐づけられている写真が存在するので削除できません
          </p>
        </v-row>
      </v-col>
    </section>
  </section>
</template>

<script>
import ModalConfirmation from "../../components/ModalConfirmation.vue";
import ModalLoading from "../../components/ModalLoading.vue";
import { getData, createData, updateData, deleteData } from "@/axios";

export default {
  name: "CategoriesEdit",

  components: {
    ModalConfirmation,
    ModalLoading,
  },

  data() {
    return {
      id: "",
      isModal: false,
      hasPhotos: false,
      loading: false,
      loadingMessage: "",
      pinImagePreview: "",
      backupImage: "",
      categories: [],
      category: {
        id: "",
        name: "",
        slug: "",
        photos: [],
        settings: {
          pin_color: "",
          pin_image: "",
        },
      },
      errorMessages: {
        name: "",
        slug: "",
      },
      japaneseItems: {
        name: "カテゴリの名前",
        slug: "URL用文字列",
      },
      pinItems: [
        {
          label: "red",
          color: "#c62626",
        },
        {
          label: "pink",
          color: "#bf1b96",
        },
        {
          label: "yellow",
          color: "#e08a0a",
        },
        {
          label: "green",
          color: "#199a10",
        },
        {
          label: "blue",
          color: "#1168d2",
        },
      ],
    };
  },

  computed: {
    isValid() {
      const errors = Object.keys(this.errorMessages).filter((key) => {
        return this.errorMessages[key].length !== 0;
      });
      return errors.length === 0;
    },
  },

  mounted() {
    getData("categories").then((res) => {
      this.categories = res.data;
    });
    if (this.$route.meta === "edit") {
      getData(`categories/${this.$route.params.id}?with=photos`).then((res) => {
        this.category = res;
        this.hasPhotos = res.photos.length > 0;
      });
    }
  },

  methods: {
    toggleModal() {
      this.isModal = !this.isModal;
    },
    onFileChange(event) {
      const files = event.target.files || event.dataTransfer.files;
      if (files.length === 0) {
        this.reset();
        return;
      }
      if (!files[0].type.match("image.*")) {
        alert("画像を選択してください。");
        this.reset();
        return;
      }
      this.showUploadImage(files[0]);
    },
    showUploadImage(file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.pinImagePreview = reader.result;
        this.category.settings.pin_image = file;
      };
      reader.readAsDataURL(file);
    },
    selectFile() {
      this.$refs.file.click();
    },
    cancelEditImage() {
      this.pinImagePreview = "";
      this.category.settings.pin_image = this.backupImage;
      this.$el.querySelector('input[type="file"]').value = null;
    },
    deleteImage() {
      this.reset();
    },
    reset() {
      this.pinImagePreview = "";
      this.category.settings.pin_image = "";
      this.$el.querySelector('input[type="file"]').value = null;
    },
    deleteItem() {
      this.loading = true;
      this.loadingMessage = "削除中...";
      deleteData(`categories/${this.$route.params.id}`)
        .then(() => {
          this.loading = false;
          this.loadingMessage = "";
          this.$store.dispatch("snackbar/setSnackbar", {
            message: `「${this.category.name}」のデータを削除しました。`,
            color: "success",
            timeout: 2000,
          });
          this.$store.dispatch("category/setCountUpdateCategories");
          if (this.$route.params.from) {
            const from = Object.assign({}, this.$route.params.from);
            const params = Object.assign({}, from);
            // 空文字のクエリーを無くす：例）words=''
            Object.keys(params).forEach((key) => {
              if (params[key] === "") delete params[key];
            });
            this.$router.push({ name: "categories", query: params });
          } else {
            this.$router.push({ name: "categories" });
          }
        })
        .catch(() => {
          this.loading = false;
          this.loadingMessage = "";
        });
    },
    save() {
      this.validate();
      if (this.isValid) {
        this.loading = true;
        this.loadingMessage = "保存中...";
        const formData = new FormData();
        formData.append("name", this.category.name);
        formData.append("slug", this.category.slug);
        formData.append("photos", this.category.photos);
        formData.append("pin_color", this.category.settings.pin_color);
        formData.append("pin_image", this.category.settings.pin_image);
        createData("categories", formData)
          .then(() => {
            this.loading = false;
            this.loadingMessage = "";
            this.$store.dispatch("snackbar/setSnackbar", {
              message: `「${this.category.name}」を追加しました。`,
              color: "success",
              timeout: 2000,
            });
            this.$store.dispatch("category/setCountUpdateCategories");
            this.$router.push({ name: "categories" });
          })
          .catch((err) => {
            this.loading = false;
            this.loadingMessage = "";
            console.log(err);
          });
      }
    },
    edit() {
      this.validate();
      if (this.isValid) {
        this.loading = true;
        this.loadingMessage = "更新中...";
        const formData = new FormData();
        formData.append("order", this.category.order);
        formData.append("name", this.category.name);
        formData.append("slug", this.category.slug);
        formData.append("photos", this.category.photos);
        formData.append("pin_color", this.category.settings.pin_color);
        formData.append("pin_image", this.category.settings.pin_image);
        updateData(`categories/${this.$route.params.id}`, formData)
          .then(() => {
            this.loading = false;
            this.loadingMessage = "";
            this.$store.dispatch("snackbar/setSnackbar", {
              message: `「${this.category.name}」を更新しました。`,
              color: "success",
              timeout: 2000,
            });
            if (this.$route.params.from) {
              const from = Object.assign({}, this.$route.params.from);
              const params = Object.assign({}, from);
              // 空文字のクエリーを無くす：例）words=''
              Object.keys(params).forEach((key) => {
                if (params[key] === "") delete params[key];
              });
              this.$router.push({ name: "categories", query: params });
            } else {
              this.$router.push({ name: "categories" });
            }
          })
          .catch((err) => {
            this.loading = false;
            this.loadingMessage = "";
            console.log(err);
          });
      }
    },
    validate() {
      Object.keys(this.errorMessages).forEach((key) => {
        this.errorMessages[key] = "";
      });
      Object.keys(this.category).forEach((key) => {
        if (key === "id") return;
        if (key === "slug") this.checkUnique(key);
        this.checkRequired(key, ["name", "slug"]);
        this.checkMaxLength(key, 50, ["name", "slug"]);
        this.checkAlpha(key, ["slug"]);
      });
    },
    checkRequired(key, requiredList) {
      if (requiredList.indexOf(key) !== -1 && this.category[key].length === 0) {
        this.errorMessages[key] = `${this.japaneseItems[key]}は必須項目です。`;
      }
    },
    checkMaxLength(key, max, targets) {
      targets.forEach((item) => {
        if (
          key === item &&
          this.category[key] &&
          max < this.category[key].length
        ) {
          this.errorMessages[
            key
          ] = `${this.japaneseItems[key]}は${max}文字以下で記入して下さい。`;
        }
      });
    },
    checkAlpha(key, targets) {
      targets.forEach((item) => {
        if (
          key === item &&
          this.category[key] &&
          !this.category[key].match(/^[A-Za-z0-9-_]+$/)
        ) {
          this.errorMessages[
            key
          ] = `${this.japaneseItems[key]}は半角英数字、半角ハイフン(-)、半角アンダースコア(_)で記入して下さい。`;
        }
      });
    },
    checkUnique(key) {
      Object.keys(this.categories).forEach((index) => {
        if (
          this.$route.meta === "edit" &&
          this.categories[index].id === Number(this.$route.params.id)
        )
          return;
        if (this.category[key] === this.categories[index][key]) {
          this.errorMessages[
            key
          ] = `「${this.category[key]}」はすでに存在する${this.japaneseItems[key]}です。`;
        }
      });
    },
  },
};
</script>

<style scoped>
.icons__upload_area {
  padding: 2rem 1rem;
  text-align: center;
  border: 0.1rem dotted #bcbcbc;
}
</style>
